import React from "react";
import { Link } from "@reach/router";

import "./landing.css";
import Principles from "./Principles";

function Landing() {
  return (
    <div id="landing-container">
      <div id="under-construction-box">
        <div id="under-construction-heading">Under Construction</div>
        <div id="under-construction-content">
          Inspired by the TTMIK video{" "}
          <a href="https://www.youtube.com/watch?v=aH04sV9W7Ao" target="_blank" rel="noreferrer">
            "12 Korean grammar points in one short sentence!"
          </a>
          , Hakyu will help students learn languages through reading sentences.
        </div>
      </div>
      <Principles />
    </div>
  );
}

export default Landing;
