import React from "react";

import { PRINCIPLES } from "../landing/Principles";

function SentenceDifficulty({ components }: any) {
  let sentenceDifficulty = 0;
  for (let i = 0; i < components.length; i++) {
    const resource = PRINCIPLES.find((P) => P.id === components[i].p);
    if (resource) {
      sentenceDifficulty = sentenceDifficulty + resource.l;
    }
  }
  sentenceDifficulty = Math.floor(sentenceDifficulty / 3);

  return (
    <div style={{ display: "flex", margin: "auto", width: "fit-content" }}>
      {new Array(5).fill(0).map((e, i) => {
        return <div key={i} style={{ height: "20px", width: "10px", background: sentenceDifficulty > i ? "#ff7057" : "transparent", marginLeft: i > 0 ? "10px" : "0px", border: "4px solid black" }} />;
      })}
    </div>
  );
}

export default SentenceDifficulty;
