export interface KoreanComponent {
  c: string;
  /** The meaning for the component if it's not a principle. */
  m?: string;
  /** The ID of the principle from our principle lookup table. */
  pid?: number;
  /** The grammar type of the word (noun, adverb, adjective, etc) */
  t?: "Time" | "Pronoun" | "Object" | "Verb" | "Noun" | "Adjective" | "Adverb";
  /** Is this word hidden when we're in generic mode */
  h?: boolean;
}

export interface EnglishComponent {
  c: EnglishWord[];
}

export interface EnglishWord {
  /** The single word in english */
  c: string;
  /** Is this word shown as the black line when we're in generic mode */
  g?: boolean;
  /** Is this word just totally hidden in generic mode (i.e. it's an english fluff-word) */
  h?: boolean;
}

export interface CombinedComponent {
  /** Korean component from the sentence. */
  c: KoreanComponent[];
  e: EnglishComponent;
  /** The position that the component sits in the English sentence. */
  p: number;
  /** Does the component need a space at the end of it to separate it from what comes next? */
  s?: boolean;
}

export const sentences: CombinedComponent[][] = [
  [
    {
      c: [
        { c: "내일", m: "Tomorrow", t: "Noun" },
        { c: "은", pid: 1 },
      ],
      e: { c: [{ c: "Tomorrow" }] },
      p: 0,
      s: true,
    },
    {
      c: [
        { c: "오늘", m: "Today", t: "Noun" },
        { c: "보다", pid: 2 },
      ],
      e: { c: [{ c: "than", g: true }, { c: "today" }] },
      p: 3,
      s: true,
    },
    {
      c: [
        { c: "추워", m: "춥다 = Cold", t: "Adjective" },
        { c: "질", pid: 3 },
      ],
      e: { c: [{ c: "be", g: true }, { c: "colder" }] },

      p: 2,
      s: true,
    },
    {
      c: [{ c: "수도 있", pid: 4 }],
      e: {
        c: [
          { c: "it", h: true },
          { c: "might", g: true },
        ],
      },
      p: 1,
    },
    { c: [{ c: "으니까", pid: 5 }], e: { c: [{ c: "so", g: true }] }, p: 4, s: true },
    {
      c: [
        { c: "옷", m: "Clothes", t: "Object" },
        { c: "을", pid: 6 },
      ],
      e: { c: [{ c: "clothes" }] },
      p: 9,
      s: true,
    },
    {
      c: [
        { c: "따뜻하", m: "따뜻하다 = Warm", t: "Adverb" },
        { c: "게", pid: 7 },
      ],
      e: { c: [{ c: "warm" }] },
      p: 8,
      s: true,
    },
    { c: [{ c: "입", m: "입다 = To Wear", t: "Verb" }], e: { c: [{ c: "wear" }] }, p: 7, s: true },
    { c: [{ c: "어야 될", pid: 8 }], e: { c: [{ c: "we'll have to", g: true }] }, p: 6, s: true },
    {
      c: [
        { c: "것 같아", pid: 9 },
        { c: "요", pid: 10 },
      ],
      e: { c: [{ c: "I think", g: true }] },
      p: 5,
    },
  ],

  [
    {
      c: [
        { c: "저", m: "I/Me", t: "Pronoun" },
        { c: "는", pid: 1 },
      ],
      e: { c: [{ c: "I" }] },
      p: 0,
      s: true,
    },
    {
      c: [
        { c: "손", m: "Hand", t: "Object" },
        { c: "을", pid: 6 },
      ],
      e: { c: [{ c: "my hands" }] },
      s: true,
      p: 3,
    },
    { c: [{ c: "더", pid: 11 }], e: { c: [{ c: "more", g: true }] }, s: true, p: 4 },
    { c: [{ c: "씻", m: "씻다 = To Wash", t: "Verb" }], e: { c: [{ c: "wash" }] }, p: 2 },
    {
      c: [
        { c: "을 거예", pid: 12 },
        { c: "요", pid: 10 },
      ],
      e: { c: [{ c: "will", g: true }] },
      p: 1,
    },
  ],
  [
    {
      c: [
        { c: "저", m: "I/Me", t: "Pronoun" },
        { c: "는", pid: 1 },
      ],
      e: { c: [{ c: "I" }] },
      p: 0,
      s: true,
    },
    {
      c: [
        { c: "친구", m: "Friend", t: "Noun" },
        { c: "들", pid: 13 },
        { c: "이랑", pid: 0 },
      ],
      s: true,
      e: { c: [{ c: "with", g: true }, { c: "my" }, { c: "friends" }] },
      p: 4,
    },
    { c: [{ c: "내일", m: "Tomorrow", t: "Time" }], s: true, e: { c: [{ c: "tomorrow." }] }, p: 5 },
    {
      c: [
        { c: "낚시", m: "Fishing", t: "Verb" },
        { c: "를", pid: 6 },
      ],
      s: true,
      e: { c: [{ c: "fishing" }] },
      p: 3,
    },
    { c: [{ c: "할", m: "하다 = To go", h: true }], s: true, e: { c: [{ c: "go", g: true }] }, p: 2 },
    {
      c: [
        { c: "것 같아", pid: 9 },
        { c: "요", pid: 10 },
      ],
      e: { c: [{ c: "will probably", g: true }] },
      p: 1,
    },
  ],
];
