import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";

import "./principles.css";

export interface Principle {
  /** The heading name. */
  p: string;
  /** The unique ID used to associate sentences with the principle. */
  id: number;
  /** A link to a resource for more information. */
  r: string;
  /** A description for the principle (a simple translation) */
  d: string;
  /** The difficulty level of the principle. */
  l: number;
}

export const PRINCIPLES: Principle[] = [
  { p: "With", id: 0, r: "https://en.wiktionary.org/wiki/%EB%93%A4", d: "-이랑 = With", l: 1 },
  { p: "Topic marker", id: 1, r: "https://www.howtostudykorean.com/unit1/unit-1-lessons-1-8/unit-1-lesson-2/", d: "-은 = Topic marker", l: 1 },
  { p: "Comparatives", id: 2, r: "https://www.howtostudykorean.com/unit1/unit-1-lessons-17-25-2/lesson-19/", d: "-보다 = Than", l: 2 },
  { p: "To become", id: 3, r: "https://funkorean4u.com/2016/04/19/grammar-adjective%EC%95%84%EC%96%B4%EC%97%AC%EC%A7%80%EB%8B%A4/", d: "-아/어/여지다 = To become", l: 2 },
  { p: "Might/Could", id: 4, r: "https://www.howtostudykorean.com/unit-2-lower-intermediate-korean-grammar/unit-2-lessons-42-50/lesson-45/", d: "-(으)ㄹ 수 있다 = Might/Could", l: 2 },
  { p: "Because (of)", id: 5, r: "https://www.howtostudykorean.com/upper-intermediate-korean-grammar/unit-4-lessons-76-83/lesson-81/", d: "-(으)니까 = Because/Since", l: 2 },
  {
    p: "Object marker",
    id: 6,
    r: `https://blogs.transparent.com/korean/object-marking-particles-in-korean/#:~:text=These%20object%20markers%20'%2Deul(,noun%20ends%20in%20a%20vowel.`,
    d: "-을 = Object marker",
    l: 1,
  },
  { p: "Making Adverbs", id: 7, r: "https://www.howtostudykorean.com/unit1/unit-1-lessons-1-8/unit-1-lesson-8/", d: "-게 = Adverb form", l: 2 },
  { p: "To have to", id: 8, r: "https://www.howtostudykorean.com/unit-2-lower-intermediate-korean-grammar/unit-2-lessons-42-50/lesson-46/", d: "아/어/여야 되다 = To have to", l: 3 },
  { p: "To think", id: 9, r: "https://www.howtostudykorean.com/unit-2-lower-intermediate-korean-grammar/unit-2-lessons-34-41/lesson-35/", d: "-(으)ㄹ 것 같다 = To think", l: 2 },
  { p: "Politeness", id: 10, r: "https://blogs.transparent.com/korean/learn-korean-conjugating-the-%EC%9A%94yo-form/", d: "-요 = Polite ending", l: 1 },
  { p: "More", id: 11, r: "https://www.howtostudykorean.com/unit1/unit-1-lessons-17-25-2/lesson-19/", d: "더 = More", l: 1 },
  { p: "Future tense", id: 12, r: "https://www.koreanwikiproject.com/wiki/(%EC%9C%BC)%E3%84%B9_%EA%B1%B0%EC%98%88%EC%9A%94", d: "-(으)ㄹ 거예요 = Future tense", l: 1 },
  { p: "Plural", id: 13, r: "https://en.wiktionary.org/wiki/%EB%93%A4", d: "-들 = Plural", l: 1 },
];

const COMPETENCY: { [key: number]: string } = {
  0: "🙁",
  1: "😐",
  2: "🙂",
};

const defaultCompetency: number[] = new Array(PRINCIPLES.length).fill(0); // Awkwardly index 0 in the array is for item id 1 in the principles!

const sortPrinciplesIntoLevels = (principles: Principle[]) => {
  const levels: Principle[][] = [];

  principles.forEach((p) => {
    if (levels[p.l]) {
      levels[p.l].push(p);
    } else {
      levels[p.l] = [p];
    }
  });
  return levels;
};

function Principles() {
  const levelledPrinciples = sortPrinciplesIntoLevels(PRINCIPLES);

  const [language, setLanguage] = useState("ENG");
  const [competency, setCompetency] = useState(defaultCompetency);

  useEffect(() => {
    const localStorageCompetency = window.localStorage.getItem("competency");
    if (localStorageCompetency) {
      console.log("Loading competencies from local storage.");
      if (JSON.parse(localStorageCompetency).length === PRINCIPLES.length) {
        setCompetency(JSON.parse(localStorageCompetency));
      } else {
        console.log("Filling missing competencies.", JSON.parse(localStorageCompetency).length, PRINCIPLES.length);
      }
    } else {
      window.localStorage.setItem("competency", JSON.stringify(defaultCompetency));
    }
  }, []);

  let percentageComplete = 0;
  for (const c in competency) {
    percentageComplete += competency[c];
  }
  percentageComplete = Math.ceil((percentageComplete / (Object.keys(PRINCIPLES).length * 2)) * 100);

  const onClickCompetency = (e: any, P: Principle) => {
    e.preventDefault();
    const userCompetencyCopy = [...competency];

    if (userCompetencyCopy[P.id] === 2) {
      userCompetencyCopy[P.id] = 0;
    } else {
      userCompetencyCopy[P.id]++;
    }
    window.localStorage.setItem("competency", JSON.stringify(userCompetencyCopy));
    setCompetency(userCompetencyCopy);
  };

  return (
    <div id="principle-area">
      <div id="principle-header">
        <div>Principles</div>
        <div
          id="language-button"
          onClick={() => {
            if (language === "ENG") {
              setLanguage("KOR");
            } else {
              setLanguage("ENG");
            }
          }}
        >
          {language}
        </div>
        <div id="percentage-complete">{`${percentageComplete}%`}</div>
      </div>

      <div id="principle-list">
        {levelledPrinciples.map((lp, li) => {
          return (
            <div key={li} className="principle-level-container">
              <div className="principle-level">{`Level ${li}`}</div>
              {lp.map((P, i) => {
                return (
                  <Link to={`sample?principle=${P.id}`} key={i}>
                    <div className={`landing-button principle p-${li}`}>
                      <span className="competency-icon" onClick={(e) => onClickCompetency(e, P)}>
                        {COMPETENCY[competency[P.id]]}
                      </span>
                      <span>{language === "ENG" ? P.p : P.d}</span>
                    </div>
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Principles;
