import React from "react";
import { Router, RouteComponentProps, Link } from "@reach/router";

import Landing from "../landing/Landing";
import Korean from "../sample/Korean";
import Hakyu from "./heading.png";
import "./app.css";

let L = (props: RouteComponentProps) => <Landing />;
let K = (props: RouteComponentProps) => <Korean />;

export default function App() {
  return (
    <>
      <div id="hakyu-logo-container">
        <Link to="/">
          <div className="tooltip">
            <img src={Hakyu} alt="Hakyu!"></img>
            <div className="tooltiptext">Click me from any page to return home</div>
          </div>
        </Link>
      </div>

      <Router>
        <L path="/" />
        <K path="sample" />
      </Router>
    </>
  );
}
