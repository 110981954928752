import React, { useState, useEffect } from "react";

import "./korean.css";
import { sentences, CombinedComponent } from "./sentences";
import { PRINCIPLES } from "../landing/Principles";
import SentenceDifficulty from "./SentenceDifficulty";

function Korean() {
  const [hoveredElement, setHoveredElement] = useState(-1);
  const [selectedElement, setSelectedElement] = useState(-1);
  const [ms, setMatchedSentences] = useState(sentences);
  const [s, setS] = useState(0);
  const [sentencePassed, setSentencePassed] = useState(false);
  const [isGeneric, setIsGeneric] = useState(false);

  // Check the URL to see if there's a selected princple, and if so try and set it as selected.
  // Only handles single principles for selection ATM but could be updated in future.
  useEffect(() => {
    const selectedPrinciple = parseInt(new URLSearchParams(window.location.search).get("principle") || "-1");

    // Find a sentence that includes our grammar principle if one is selected, otherwise just return the first sentence.
    let localS: CombinedComponent[][] = [];
    if (selectedPrinciple > -1) {
      sentences.forEach((possibleSentence) => {
        possibleSentence.forEach((kc) => {
          kc.c.forEach((kcc) => {
            if (kcc.pid === selectedPrinciple) {
              localS.push(possibleSentence);
            }
          });
        });
      });

      if (localS.length > 0) {
        localS[s].forEach((kc, i: number) => {
          if (kc.c.find((kce: any) => kce.pid === selectedPrinciple)) {
            setSelectedElement(i);
            return;
          }
        });
      }
    } else {
      localS.push(sentences[s]);
    }

    setMatchedSentences(localS);
  }, [s]);

  // This function checks if the user has passed all of the principles for the currently selected sentence.
  useEffect(() => {
    const localStorageCompetency = window.localStorage.getItem("competency");
    if (localStorageCompetency) {
      console.log("Loading competencies from local storage.");
      const lscObj = JSON.parse(localStorageCompetency);
      const sentencePrinciples = ms[s]?.map((kc) => kc.c).flat();
      let isCompetent = true;
      sentencePrinciples?.forEach((sp) => {
        if (sp.pid && lscObj[sp.pid] === 0) {
          isCompetent = false;
        }
      });
      setSentencePassed(isCompetent);
    }
  }, [ms, s]);

  if (s === undefined || !ms[s]) {
    return <div />;
  }

  return (
    <>
      {ms.length > 1 && (
        <div style={{ display: "flex", width: "fit-content", margin: "0px auto 40px auto" }}>
          <div style={{ width: "100px" }}>
            {s + 1 > 1 && (
              <div
                style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
                onClick={() => {
                  setS(s - 1);
                }}
              >
                Previous
              </div>
            )}
          </div>

          <div style={{ margin: "0px 20px", width: "100px" }}>{`${s + 1} of ${ms.length} `}</div>
          <div style={{ width: "100px" }}>
            {s + 1 < ms.length && (
              <div
                style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
                onClick={() => {
                  setS(s + 1);
                }}
              >
                Next
              </div>
            )}
          </div>
        </div>
      )}
      <div style={{ margin: "0px auto 30px auto", width: "fit-content" }}>
        <SentenceDifficulty components={ms[s]} />
        <div
          className="generic-button"
          onClick={() => {
            setIsGeneric(!isGeneric);
          }}
        >
          {isGeneric ? "Generic Mode" : "Specific Mode"}
        </div>
      </div>

      <div
        className="sentence-wrapper"
        onMouseLeave={() => {
          setHoveredElement(-1);
        }}
      >
        {ms[s].map((sc, i) => {
          return (
            <span key={i}>
              <span
                className={`korean-component ${hoveredElement === i ? "k-hovered" : ""} ${selectedElement === i ? "k-selected" : ""}`}
                onMouseEnter={() => {
                  setHoveredElement(i);
                }}
                onClick={() => {
                  if (selectedElement === i) {
                    setSelectedElement(-1);
                  } else {
                    setSelectedElement(i);
                  }
                }}
              >
                {sc.c.map((c) => {
                  // In generic mode show the type for the word if it's supposed to be hidden.
                  if (isGeneric && c.m && !c.h) {
                    return <span className="generic-korean-word">{c.t}</span>;
                  } else {
                    return c.c;
                  }
                })}
              </span>
              {sc.s && " "}
            </span>
          );
        })}
        {sentencePassed && <span id="sentence-passed">✔️</span>}
      </div>

      <div
        className="sentence-wrapper"
        onMouseLeave={() => {
          setHoveredElement(-1);
        }}
      >
        {Object.assign([], ms[s])
          .sort((a: CombinedComponent, b: CombinedComponent) => {
            return a.p > b.p ? 1 : -1;
          })
          .map((ec: CombinedComponent, i) => {
            let koreanPosition = -1;
            ms[s].forEach((sc, kp) => {
              if (sc.p === i) {
                koreanPosition = kp;
              }
            });

            return (
              <span key={i}>
                <span
                  className={`english-component ${ms[s][hoveredElement]?.p === i ? "e-hovered" : ""} ${ms[s][selectedElement]?.p === i ? "e-selected" : ""}`}
                  onMouseEnter={() => {
                    setHoveredElement(koreanPosition);
                  }}
                >
                  {ec.e.c?.map((ecec, i) => {
                    if (isGeneric && ecec.h) {
                      // If we're handling a fluff word then we just hide it completely.
                      return <span />;
                    } else if (isGeneric && !ecec.g) {
                      // If we're hiding something that's not a generic part of the sentence then we put the black line.
                      return <span className="generic-english-word" />;
                    } else {
                      // If it's just a generic term then we show it.
                      return <span style={{ marginRight: i + 1 < ec.e.c.length ? "10px" : "" }}>{ecec.c}</span>;
                    }
                  })}
                </span>{" "}
              </span>
            );
          })}
      </div>
      <div style={{ marginTop: "60px" }} id="principle-area">
        {ms[s][selectedElement > -1 ? selectedElement : hoveredElement]?.c.map((c, i) => {
          const resource = PRINCIPLES.find((P) => P.id === c.pid);

          if (isGeneric && !resource) {
            return null;
          }

          return (
            <div key={i}>
              {resource ? (
                <>
                  <span>{resource.d}</span>
                  <a href={resource.r} target="_blank" rel="noreferrer" style={{ marginLeft: "20px" }}>
                    Learn
                  </a>
                </>
              ) : (
                <span>{`${c.c} = ${c.m}`}</span>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Korean;
